import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ClientFiltrableDataSource } from 'filtrable-data-source';
import {
  DisplayBoDtoNBK,
  FolderBoDtoNBK,
  RecipeBoDtoNBK
} from 'src/app/api/nbk';
import { getCookingModeList, SelectableCookingMode } from 'src/app/core/utils';
import { MyDisplayService } from '../my-display.service';

export interface recipeDrop {
  recipe: RecipeBoDtoNBK;
  newIndex: number;
  currentPage: number;
}

const RECIPES_PER_PAGE = 10;

@Component({
  selector: 'app-display-oracle',
  templateUrl: './display-oracle.component.html',
  styleUrls: ['./display-oracle.component.scss']
})
export class DisplayOracleComponent implements OnInit {
  dataSourceRecipes: ClientFiltrableDataSource<RecipeBoDtoNBK> =
    new ClientFiltrableDataSource();
  dataSourceFolders: ClientFiltrableDataSource<FolderBoDtoNBK> =
    new ClientFiltrableDataSource();
  totalPagesRecipes: number;
  totalPagesFolder: number;
  cookingModeList: SelectableCookingMode[];
  isEmpty: boolean = false;
  switchPageTimeout: number;
  isDraggingRecipe: boolean = false;

  @Input() recipes: RecipeBoDtoNBK[] = [];
  @Input() folders: FolderBoDtoNBK[] = [];
  @Input() cookingMode: DisplayBoDtoNBK.CookingModeEnum = 'COMBI';

  @Output() onDragEndRecipesDisplay: EventEmitter<recipeDrop> = new EventEmitter();

  @Output() onDragEndRecipesFolder: EventEmitter<{
    recipe: RecipeBoDtoNBK;
    folder: any;
  }> = new EventEmitter();

  @Output() onFolderDeleted: EventEmitter<FolderBoDtoNBK> = new EventEmitter();

  constructor(
    private myDisplayService: MyDisplayService,
    private ts: TranslateService
  ) {
    //TODO to review when enabled other device management
    this.cookingModeList = getCookingModeList('ORACLE');
  }

  ngOnInit(): void {
    this.dataSourceRecipes.limit = RECIPES_PER_PAGE;
    this.dataSourceFolders.limit = 5;

    this.dataSourceRecipes.setItems(this.recipes);
    this.dataSourceRecipes.setPage(0);

    this.dataSourceFolders.setItems(this.folders);
    this.dataSourceFolders.setPage(0);

    this.totalPagesRecipes = this.getTotalPages(this.dataSourceRecipes);
    this.totalPagesFolder = this.getTotalPages(this.dataSourceFolders);

    this.isEmpty = this.recipes.length === 0 && this.folders.length === 0;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.recipes) {
      this.dataSourceRecipes.setItems(this.recipes);
      if (
        this.myDisplayService.goToLastPage ||
        this.dataSourceRecipes.items?.length! % this.dataSourceRecipes.limit ===
          0 ||
        this.myDisplayService.resetDisplayRecipe == true
      ) {
        this.myDisplayService.goToLastPage = false;
        this.totalPagesRecipes = this.getTotalPages(this.dataSourceRecipes);
        const newPage =
          this.totalPagesRecipes - 1 < 0 ? 0 : this.totalPagesRecipes - 1;
        this.dataSourceRecipes.setPage(newPage);
      }

      if (this.myDisplayService.resetDisplayRecipe == true) {
        this.totalPagesRecipes = this.getTotalPages(this.dataSourceRecipes);
        this.dataSourceRecipes.setPage(0);
        this.myDisplayService.resetDisplayRecipe = false;
      }
    }

    if (changes.folders) {
      this.dataSourceFolders.setItems(this.folders);
      this.totalPagesFolder = this.getTotalPages(this.dataSourceFolders);
      this.dataSourceFolders.setPage(this.totalPagesFolder - 1);

      if (this.myDisplayService.resetDisplayFolder == true) {
        this.totalPagesFolder = this.getTotalPages(this.dataSourceFolders);
        this.dataSourceFolders.setPage(0);
        this.myDisplayService.resetDisplayFolder = false;
      }
    }

    this.isEmpty = this.recipes.length === 0 && this.folders.length === 0;
  }

  nextPage(
    dataSource:
      | ClientFiltrableDataSource<RecipeBoDtoNBK>
      | ClientFiltrableDataSource<FolderBoDtoNBK>
  ) {
    const { page, limit, totalItems } = dataSource;
    if (totalItems && totalItems > (page + 1) * limit) {
      dataSource.setPage(page + 1);
    } else {
      dataSource.setPage(0);
    }
  }

  prevPage(
    dataSource:
      | ClientFiltrableDataSource<RecipeBoDtoNBK>
      | ClientFiltrableDataSource<FolderBoDtoNBK>
  ) {
    const { page } = dataSource;
    if (page != 0) {
      dataSource.setPage(page - 1);
    } else {
      const totalPages = this.getTotalPages(dataSource);
      dataSource.setPage(totalPages - 1);
    }
  }

  onDropDisplay(event: any) {
    this.myDisplayService.recipeDragged = event.data;
    const recipe: recipeDrop = {
      recipe: event.data,
      newIndex: event.index - this.dataSourceRecipes.page * RECIPES_PER_PAGE,
      currentPage: this.dataSourceRecipes.page
    };
    this.onDragEndRecipesDisplay.emit(recipe);
  }

  onPreviousPageDragStart(): void {
    let timeout = 200;
    if (this.switchPageTimeout) {
      clearTimeout(this.switchPageTimeout);
      this.switchPageTimeout = 0;
      timeout = 2000;
    }
    this.switchPageTimeout = setTimeout(() => {
      this.prevPage(this.dataSourceRecipes);
      this.onPreviousPageDragStart();
    }, timeout);
  }

  onNextPageDragStart(): void {
    let timeout = 200;
    if (this.switchPageTimeout) {
      clearTimeout(this.switchPageTimeout);
      this.switchPageTimeout = 0;
      timeout = 1500;
    }
    this.switchPageTimeout = setTimeout(() => {
      this.nextPage(this.dataSourceRecipes);
      this.onNextPageDragStart();
    }, timeout);
  }

  onSwitchPageDragLeave(): void {
    if (this.switchPageTimeout) {
      clearTimeout(this.switchPageTimeout);
      this.switchPageTimeout = 0;
    }
  }

  onDropFolder(event: any, folder: any) {
    this.myDisplayService.recipeDragged = event.data;
    this.onDragEndRecipesFolder.emit({ recipe: event.data, folder: folder });
  }

  onDragRecipesStart(event: any) {
    this.myDisplayService.isAlreadyInDisplay = true;
    this.myDisplayService.recipeOnDragging = event.target.id;
    this.isDraggingRecipe = true;
  }

  onDragging(id: number) {
    const element = document.querySelector('#recipe_box_' + id);
    element?.classList.add('hide-box');
  }

  onDragEnd(id: number) {
    this.myDisplayService.isAlreadyInDisplay = false;
    const element = document.querySelector('#recipe_box_' + id);
    element?.classList.remove('hide-box');
    this.isDraggingRecipe = false;
    clearTimeout(this.switchPageTimeout);
    this.switchPageTimeout = 0;
  }

  private getTotalPages(
    dataSource:
      | ClientFiltrableDataSource<RecipeBoDtoNBK>
      | ClientFiltrableDataSource<FolderBoDtoNBK>
  ) {
    const { limit, totalItems } = dataSource;
    return Math.floor((totalItems! + limit - 1) / limit);
  }

  deleteFolder(folder: FolderBoDtoNBK) {
    this.onFolderDeleted.emit(folder);
  }


}
