import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Subject } from 'rxjs';
import {
  getCookingModeList,
  getDeviceModelList,
  SelectableCookingMode,
  SelectableDeviceModel
} from 'src/app/core/utils';
import { MyDisplayFiltersService } from './my-display-filters.service';
import { TranslateService } from '@ngx-translate/core';
import { DictionaryService } from 'src/app/core/dictionary.service';

@Component({
  selector: 'app-my-display-filters',
  templateUrl: './my-display-filters.component.html',
  styleUrls: ['./my-display-filters.component.scss']
})
export class MyDisplayFiltersComponent implements OnInit, OnDestroy {
  displayFilterForm: UntypedFormGroup;
  deviceModelList: SelectableDeviceModel[];
  cookingModelList: SelectableCookingMode[];

  unsubscribe$ = new Subject<void>();

  @Output() filterChanged = new EventEmitter();
  @Output() filterCleaned = new EventEmitter();

  constructor(
    private ts: TranslateService,
    private ds: DictionaryService,
    private recipeFiltersService: MyDisplayFiltersService,
  ) {
    this.displayFilterForm = new UntypedFormGroup(
      {
        name: new UntypedFormControl(''),
        device: new UntypedFormControl(''),
        recipe: new UntypedFormControl(''),
        //TODO to remove when enabled other device management
        deviceModel: new UntypedFormControl('ORACLE'),
        cookingMode: new UntypedFormControl(''),
        excludeDefualt: new UntypedFormControl(''),
      }
    );

    this.deviceModelList = getDeviceModelList();
    //TODO to remove when enabled other device management
    this.cookingModelList = getCookingModeList('ORACLE');
  }

  ngOnInit(): void {
    this.displayFilterForm.get('deviceModel')?.valueChanges.subscribe((v) => {
      this.cookingModelList = getCookingModeList(v);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  search() {
    this.recipeFiltersService.setFilterForm(this.displayFilterForm.value);
    this.filterChanged.emit(this.displayFilterForm.value);
  }

  clean() {
    this.displayFilterForm.reset();
    this.filterCleaned.emit();
    //TODO da rimuovere quando ci saranno altri device
    this.displayFilterForm.get('deviceModel')?.patchValue('ORACLE')
  }


}
