<div class="display-action-box row py-4">
  <div
    class="col-6 d-flex flex-column justify-content-center align-items-center"
    [dndDropzone]="[]"
    (dndDrop)="onDropDelete($event)"
    dndDragoverClass="custom-drag-over"
    [dndDisableIf]="!myDisplayService.isAlreadyInDisplay"
    [ngClass]="{'disabled': !myDisplayService.isAlreadyInDisplay}"
  >
    <img src="../../../assets/images/delete_recipe.png" />
    <p>{{ 'MY_DISPLAY.REMOVE_RECIPE' | translate}}</p>
  </div>
  <div
    class="col-6 d-flex justify-content-center align-items-center"
    [dndDropzone]="[]"
    (dndDrop)="onDropNewFolder($event, 'HSO')"
    dndDragoverClass="custom-drag-over"
    *ngIf="cookingMode === 'HSO'"
  >
    <img
      src="../../../assets/images/new-folder.png"
    />
    <p>{{ 'MY_DISPLAY.ADD_FOLDER' | translate}}</p>
  </div>
  <div
    class="col-6 d-flex flex-column justify-content-center align-items-center"
    [dndDropzone]="[]"
    (dndDrop)="onDropNewFolder($event, 'COMBI')"
    dndDragoverClass="custom-drag-over"
    *ngIf="cookingMode === 'COMBI'"
  >
    <img
      src="../../../assets/images/new-folder.png"
    />
    <p>{{ 'MY_DISPLAY.ADD_FOLDER' | translate}}</p>
  </div>
</div>
