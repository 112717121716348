<div class="filter-box d-flex justify-content-center">
  <label class="custom-label">
    {{ 'MY_DISPLAY.FILTER' | translate }}
    <input type="text" class="custom-input" [formControl]="filterRecipe" />
  </label>
</div>
<div class="recipes-box">
  <div
    class="d-flex"
    [ngClass]="{ 'flex-wrap recipes-box-centerd': !responsiveMode }"
  >
    <app-recipe-box
      *ngFor="let r of dataSource.currentItems"
      [recipe]="r"
      [customClass]="'on-display'"
      (onDragRecipeStart)="onDragRecipesStart()"
      (onDragRecipeEnd)="myDisplayService.isAlreadyInDisplay = false"
      [draggable]="true"
    ></app-recipe-box>
  </div>
</div>

<div class="recipes-pagination">
  <ngb-pagination
    [pageSize]="dataSource.limit"
    [page]="dataSource.page + 1"
    (pageChange)="dataSource.setPage($event - 1)"
    [collectionSize]="dataSource.totalItems || 0"
    [maxSize]="5"
  ></ngb-pagination>
</div>
