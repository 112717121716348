import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import {
  DisplayBoDtoNBK,
  DisplayService,
  FolderBoDtoNBK,
  FolderService,
  MyNabooService,
  UserKeycloakDtoNBK,
  RecipeBoDtoNBK
} from '../api/nbk';
import { NewFolderDrop } from './display-actions/display-actions.component';
import { recipeDrop } from './display-oracle/display-oracle.component';
import { DisplayRecipesComponent } from './display-recipes/display-recipes.component';
import { MyDisplayService } from './my-display.service';
import { polyfill } from 'mobile-drag-drop';
import { scrollBehaviourDragImageTranslateOverride } from 'mobile-drag-drop/scroll-behaviour';
import { KEY_LANGUAGE_STORAGE } from '../core/utils';
import { UntypedFormControl, Validators } from '@angular/forms';

export type FolderAndRecipe = {
  recipe: RecipeBoDtoNBK;
  folder: FolderBoDtoNBK;
};

export type FolderExtended = {
  folder: FolderBoDtoNBK;
  recipeInFolder: RecipeBoDtoNBK[];
};

@Component({
  selector: 'app-my-display',
  templateUrl: './my-display.component.html',
  styleUrls: ['./my-display.component.scss']
})
export class MyDisplayComponent implements OnInit, OnDestroy {
  getFromMyNaboo$ = new BehaviorSubject<DisplayBoDtoNBK.CookingModeEnum| undefined>(undefined);

  canRenameDisplay: boolean = false;
  newDisplayName: UntypedFormControl;

  recipesInDisplay: RecipeBoDtoNBK[] = [];
  recipesFromMyNaboo: RecipeBoDtoNBK[] = [];
  foldersCombi: FolderBoDtoNBK[] = [];
  foldersHso: FolderBoDtoNBK[] = [];
  foldersExtended: FolderExtended[] = [];

  folders: FolderBoDtoNBK[] = [];
  display: DisplayBoDtoNBK;

  showRecipesResponsive: boolean = false;

  @ViewChild('recipes') recipesComponent: DisplayRecipesComponent;

  unsubscribe$ = new Subject();
  kcUser: UserKeycloakDtoNBK;

  constructor(
    private myDisplayService: MyDisplayService,
    private displayService: DisplayService,
    private myNabooService: MyNabooService,
    private folderService: FolderService,
    private ts: TranslateService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.display = this.route.snapshot.data.displayData.display;
    this.kcUser = this.route.snapshot.data.displayData.loggedUser;
    this.renderDisplay();

    this.newDisplayName = new UntypedFormControl(this.display.name, Validators.required)

    this.getFromMyNaboo$
      .pipe(
        switchMap((cookingmode) =>
          this.myNabooService
            .getAllMyNabooMyNaboos(localStorage.getItem(KEY_LANGUAGE_STORAGE)!,undefined,
            undefined,false,undefined,`recipe/cookingMode eq ${cookingmode!}`)
        ),
        tap((data) => this.recipesFromMyNaboo = data.value!),
        takeUntil(this.unsubscribe$)
      ).subscribe();

    this.myDisplayService.reorderRecipe$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => {
          const copyFolder = [...this.folders].sort(this.sortFolder);
          this.folders = [...copyFolder];
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    polyfill({
      dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride,
      dragImageCenterOnTouch: true
    });

    this.refreshRecipesToAdd();

    try {
      window.addEventListener('touchmove', function () {}, { passive: false });
    } catch (e) {}
  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }

  onRenameDisplay() {
    this.displayService.renameDisplay(this.display.id!, this.newDisplayName.value)
    .pipe(
      takeUntil(this.unsubscribe$),
      tap(() => {
        this.toastrService.success(
          this.ts.instant('MY_DISPLAY.DISPLAY_UPDATED'),
          this.ts.instant('GLOBAL.SUCCESS')
        );
        this.display.name = this.newDisplayName.value;
        this.canRenameDisplay = false;
      })
    ).subscribe();
  }

  undoRenameDisplay() {
    this.canRenameDisplay = false;
    this.newDisplayName.patchValue(this.display.name)
  }

  backToList() {
    this.router.navigate(['/', 'my-display']);
  }

  /**
   * Drop a recipe inside display
   * @param recipeDrop
   */
  addRecipe(recipeDrop: recipeDrop) {
    const recipe = recipeDrop.recipe;

    if (
      recipe.cookingMode === this.display.cookingMode
    ) {
      !this.myDisplayService.isAlreadyInDisplay
        ? this.newRecipe(recipeDrop)
        : this.reorderRecipe(recipeDrop);

      this.myDisplayService.isAlreadyInDisplay = false;
    } else {
      this.toastrService.error(
        this.ts.instant('GLOBAL.WARNING'),
        this.ts.instant('MY_DISPLAY.ERROR_COOKINGMODE')
      );
    }
  }

  /**
   * Recipe deleted from display
   * @param recipe
   */
  deleteRecipe(recipe: RecipeBoDtoNBK) {
    this.displayService
      .removeRecipeFromDisplay(this.display.id!, recipe.id!)
      .pipe(
        take(1),
        tap(() => {
          const copyRecipesInDisplay = [...this.recipesInDisplay].filter(
            (el) => el.id !== recipe.id
          );
          this.recipesInDisplay = [...copyRecipesInDisplay];
          this.myDisplayService.goToLastPage = false;
          this.refreshRecipesToAdd();
          this.toastrService.success(
            this.ts.instant('MY_DISPLAY.RECIPE_DELETED_DISPLAY'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
        })
      )
      .subscribe();
  }

  /**
   * Add a recipe to a folder
   * @param params
   * @returns
   */
  addRecipeToFolder(params: FolderAndRecipe) {
    if (params.recipe.cookingMode !== this.display.cookingMode) {
      this.toastrService.error(
        this.ts.instant('GLOBAL.WARNING'),
        this.ts.instant('MY_DISPLAY.ERROR_COOKINGMODE')
      );
      return;
    }

    this.folderService
      .addRecipeToFolder(params.folder.id!, params.recipe.id!)
      .pipe(
        take(1),
        tap((data) => {
          if (this.myDisplayService.isAlreadyInDisplay) {
            const copyRecipesInDisplay = [...this.recipesInDisplay].filter(
              (el) => el.id !== params.recipe.id
            );
            this.recipesInDisplay = [...copyRecipesInDisplay];
          }
          const alreadyInDisplay = params.folder.recipes?.some(
            (r) => r.id === params.recipe.id
          );
          if (!alreadyInDisplay) {
            params.folder.recipes = [...params.folder.recipes!, params.recipe];
            this.refreshRecipesToAdd();
            this.toastrService.success(
              this.ts.instant('MY_DISPLAY.RECIPE_ADDED_FOLDER'),
              this.ts.instant('GLOBAL.SUCCESS')
            );
          } else {
            this.toastrService.error(
              this.ts.instant('GLOBAL.WARNING'),
              this.ts.instant('MY_DISPLAY.RECIPE_ALREADY_IN_FOLDER')
            );
          }
        })
      )
      .subscribe();
  }

  /**
   * Create new folder
   * @param data
   * @returns
   */
  addNewFolder(data: NewFolderDrop) {
    const { recipe, cookingMode } = data;

    if (recipe.cookingMode !== cookingMode) {
      this.toastrService.error(
        this.ts.instant('GLOBAL.WARNING'),
        this.ts.instant('MY_DISPLAY.ERROR_COOKINGMODE')
      );
      return;
    }

    const folder: FolderBoDtoNBK = {
      name: this.generateNameFolder(),
      recipes: [{ id: recipe.id }]
    };

    this.folderService
      .insertFolder(localStorage.getItem(KEY_LANGUAGE_STORAGE)!, folder)
      .pipe(
        switchMap((data) => {
          this.folders = [...this.folders, data].sort(this.sortFolder);
          return this.displayService.addFolderToDisplay(
            this.display.id!,
            data.id!
          );
        }),
        tap(() => {
          this.toastrService.success(
            this.ts.instant('MY_DISPLAY.NEW_FOLDER_CREATED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
        }),
        take(1)
      )
      .subscribe();

    this.myDisplayService.goToLastPage = false;
  }

  /**
   * Delete a folder
   * @param folder
   */
  deleteFolder(folder: FolderBoDtoNBK) {
    this.folderService
      .deleteFolder(folder.id!)
      .pipe(
        take(1),
        tap(() => {
          const newFolder = [...this.folders];
          this.folders = newFolder.filter((el) => el.id !== folder.id);
          this.refreshRecipesToAdd();
        })
      )
      .subscribe();
  }

  /**
   * Refresh of the recipes users can add to display
   */
  refreshRecipesToAdd() {
    this.getFromMyNaboo$.next(this.display.cookingMode);
  }

  private newRecipe(recipeDrop: recipeDrop) {
    const recipe = recipeDrop.recipe;
    let { newIndex, currentPage } = recipeDrop;
    if (currentPage === -1) currentPage = 0;
    newIndex = newIndex + 10 * currentPage;
    this.displayService
      .addRecipeToDisplay(this.display.id!, recipe.id!, newIndex)
      .pipe(
        take(1),
        tap((data) => {
          const newRecipes = [...this.recipesInDisplay];
          const alreadyInDisplay = newRecipes.some((r) => r.id === recipe.id);
          if (!alreadyInDisplay) {
            newRecipes.splice(newIndex!, 0, recipe!);
            this.myDisplayService.goToLastPage = true;
            this.toastrService.success(
              this.ts.instant('MY_DISPLAY.RECIPE_ADDED_DISPLAY'),
              this.ts.instant('GLOBAL.SUCCESS')
            );
          } else {
            this.toastrService.error(
              this.ts.instant('MY_DISPLAY.RECIPE_ALREADY_IN_DISPLAY'),
              this.ts.instant('GLOBAL.ERROR')
            );
          }
          this.recipesInDisplay = [...newRecipes];
        })
      )
      .subscribe();
  }

  private reorderRecipe(recipeDrop: recipeDrop) {
    const recipe = recipeDrop.recipe;
    let { newIndex, currentPage } = recipeDrop;
    const oldIndex = this.recipesInDisplay.findIndex((r) => r.id === recipe.id);

    newIndex = newIndex + 10 * currentPage;

    if (newIndex !== 0) {
      if (newIndex >= this.recipesInDisplay?.length!) {
        newIndex = this.recipesInDisplay?.length! - 1;
      } else if (newIndex > oldIndex) {
        newIndex--;
      }
    }

    this.displayService
      .addRecipeToDisplay(this.display.id!, recipe.id!, newIndex)
      .pipe(
        take(1),
        tap(() => {
          const newRecipes = [...this.recipesInDisplay];
          newRecipes.splice(oldIndex, 1);
          newRecipes.splice(newIndex!, 0, recipe!);

          this.recipesInDisplay = [...newRecipes];
        }),
        tap(() => {
          this.toastrService.success(
            this.ts.instant('MY_DISPLAY.RECIPE_ORDERED_DISPLAY'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
        })
      )
      .subscribe();
  }

  private renderDisplay() {
    this.recipesInDisplay = [...this.display.recipes!];
    this.folders = [...this.display.folders!].sort(this.sortFolder);
    this.myDisplayService.resetDisplayRecipe = true;
    this.myDisplayService.resetDisplayFolder = true;
  }

  private generateNameFolder() {
    const regex = new RegExp(
      `${this.ts.instant('MY_DISPLAY.NEW_FOLDER')}[\(]?([0-9]+)?[\)]?]?`
    );
    const foldersWithRegex = this.folders.filter((f) => regex.test(f.name!));
    let counterName = foldersWithRegex.length === 0 ? 0 : 1;

    foldersWithRegex.forEach((r) => {
      const arrayName = r.name!.split(this.ts.instant('MY_DISPLAY.NEW_FOLDER'));
      if (
        arrayName.length > 1 &&
        r.name !== this.ts.instant('MY_DISPLAY.NEW_FOLDER')
      ) {
        const counter = parseInt(
          arrayName[1].substring(1, arrayName[1].length - 1)
        );
        if (counter > counterName) counterName = counter + 1;
        else if (counter === counterName) counterName = counterName + 1;
      }
    });

    return counterName === 0
      ? this.ts.instant('MY_DISPLAY.NEW_FOLDER')
      : this.ts.instant('MY_DISPLAY.NEW_FOLDER') + `(${counterName})`;
  }

  private sortFolder(x: FolderBoDtoNBK, y: FolderBoDtoNBK) {
    if (x.name! < y.name!) {
      return -1;
    }
    if (x.name! > y.name!) {
      return 1;
    }
    return 0;
  }

}
