import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DisplayBoDtoNBK, RecipeBoDtoNBK } from 'src/app/api/nbk';
import { MyDisplayService } from '../my-display.service';

export type NewFolderDrop = {
  recipe: RecipeBoDtoNBK,
  cookingMode: RecipeBoDtoNBK.CookingModeEnum
}

@Component({
  selector: 'app-display-actions',
  templateUrl: './display-actions.component.html',
  styleUrls: ['./display-actions.component.scss']
})
export class DisplayActionsComponent implements OnInit {

  @Input() cookingMode: DisplayBoDtoNBK.CookingModeEnum;

  @Output() onDragEndDeleteRecipes: EventEmitter<RecipeBoDtoNBK> =
    new EventEmitter();
  @Output() onDragEndNewFolder: EventEmitter<NewFolderDrop> =
    new EventEmitter();

  constructor(public myDisplayService: MyDisplayService) { }

  ngOnInit(): void {
  }

  onDropDelete(event: any) {
    this.onDragEndDeleteRecipes.emit(event.data);
  }

  onDropNewFolder(event: any, cookingMode: RecipeBoDtoNBK.CookingModeEnum) {
    const data: NewFolderDrop =  {
      recipe: event.data,
      cookingMode: cookingMode
    }
    this.onDragEndNewFolder.emit(data);
  }

}
