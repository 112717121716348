<h1>{{ 'NAVIGATION.MY_DISPLAY' | translate }}</h1>
<h2>
  {{ 'MY_DISPLAY.HEADER' | translate }}
  <div class="button-box">
    <button class="button-primary" (click)="backToList()">
      <i class="fa fa-chevron-left"></i> 
      {{ 'GLOBAL.BACK' | translate }}
    </button>
  </div>
</h2>
<div id="display-header">
  <div id="display-name">
    <label class="custom-label">
      {{'MY_DISPLAY.FILTER' | translate }}
    </label>
    <div class="display-name-component">
      <input
        type="text"
        [formControl]="newDisplayName"
        class="custom-input grow"
        [ngClass]="!canRenameDisplay ? 'readonly-input' : ''"
        [readonly]="!canRenameDisplay"
        [placeholder]="'MY_DISPLAY.FILTER' | translate"
      />
      <div *ngIf="!display.constructor" >
          <button class="button-icon edit-button" *ngIf="!canRenameDisplay"
            [tooltip]="'MY_DISPLAY.CHANGE_NAME' | translate" 
            (click)="canRenameDisplay = true">
            <i class="fa fa-edit"></i>
          </button>
          <button class="button-icon undo-button" *ngIf="canRenameDisplay"
            [tooltip]="'GLOBAL.UNDO' | translate" 
            (click)="undoRenameDisplay()">
            <i class="fas fa-undo"></i>
          </button>
          <button class="button-icon save-button" *ngIf="canRenameDisplay"
            [disabled]="!newDisplayName.valid"
            [tooltip]="'GLOBAL.SAVE' | translate" 
            (click)="onRenameDisplay()">
            <i class="fas fa-save"></i>
          </button>
        </div>
      </div>
  </div>
  
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-lg-10 col-xl-9">
      <app-display-oracle
        [recipes]="recipesInDisplay"
        [folders]="folders"
        [cookingMode]="display.cookingMode!"
        (onDragEndRecipesDisplay)="addRecipe($event)"
        (onDragEndRecipesFolder)="addRecipeToFolder($event)"
        (onFolderDeleted)="deleteFolder($event)"
      ></app-display-oracle>
      <div class="col-12">
        <app-display-actions
          [cookingMode]="display.cookingMode!"
          (onDragEndDeleteRecipes)="deleteRecipe($event)"
          (onDragEndNewFolder)="addNewFolder($event)"
        ></app-display-actions>
      </div>
    </div>
    <div class="col-12 col-lg-2 col-xl-3">
      <app-display-recipes
        #recipes
        [recipes]="recipesFromMyNaboo"
      ></app-display-recipes>
    </div>
  </div>
</div>
