<div class="row filter-box pb-xl-4" [formGroup]="displayFilterForm">
  <div class="col-12 col-xl-10">
    <div class="row">
      <div class="col-12 col-xl-4 pt-3">
        <label class="custom-label w-100">
          {{ 'MY_DISPLAY.DISPLAY_NAME' | translate }}
          <input
            type="text"
            class="custom-input"
            formControlName="name"
            [placeholder]="'MY_DISPLAY.DISPLAY_NAME' | translate"
          />
        </label>
      </div>
      <div class="col-12 col-xl-4 pt-3">
        <label class="custom-label w-100">
          {{ 'RECIPE.DEVICE_MODEL' | translate }}
          <ng-select
            [items]="deviceModelList"
            formControlName="deviceModel"
            classList="custom-input"
            bindValue="id"
            bindLabel="name"
            [clearable]="false"
            [searchable]="false"
            [placeholder]="'RECIPE.DEVICE_MODEL' | translate"
            [readonly]="true"
          >
          <ng-template ng-option-tmp ng-label-tmp let-item="item">
            {{ item.name | translate }}
          </ng-template>
        </ng-select>
        </label>
      </div>
      <div class="col-12 col-xl-4 pt-3">
        <label class="custom-label w-100">
          {{ 'RECIPE.COOKING_MODE' | translate }}
          <ng-select
            [items]="cookingModelList"
            formControlName="cookingMode"
            classList="custom-input"
            bindValue="id"
            bindLabel="name"
            [searchable]="false"
            [placeholder]="'RECIPE.COOKING_MODE' | translate"
          >
          <ng-template ng-option-tmp ng-label-tmp let-item="item">
            {{ item.name | translate }}
          </ng-template>
        </ng-select>
        </label>
      </div>
      <div class="col-12 col-xl-4 pt-3">
        <label class="custom-label w-100">
          {{ 'MY_DISPLAY.DEVICE_FILTER' | translate }}
          <input
            type="text"
            class="custom-input"
            formControlName="device"
            [placeholder]="'MY_DISPLAY.DEVICE_FILTER' | translate"
          />
        </label>
      </div>
      <div class="col-12 col-xl-4 pt-3">
        <label class="custom-label w-100">
          {{ 'RECIPES.FILTERS.PLACEHOLDER_NAME' | translate }}
          <input
            type="text"
            class="custom-input"
            formControlName="recipe"
            [placeholder]="'RECIPES.FILTERS.PLACEHOLDER_NAME' | translate"
          />
        </label>
      </div>
      <div class="col-12 col-xl-4 pt-3 align-content-center">
        <label class="custom-label w-100">
          <input type="checkbox" formControlName="excludeDefualt" />
          {{ 'FILTERS.EXCLUDE_DEFUALT' | translate }}
        </label>
      </div>
    </div>
  </div>

  <div class="col-12 col-xl-2">
    <div class="filter-button-box">
      <button
        class="button-primary mb-2"
        (click)="search()"
        [disabled]="!displayFilterForm.valid"
      >
        <i class="fa fa-fw fa-search"></i>{{ 'GLOBAL.FIND' | translate }}
      </button>
      <button class="button-secondary mb-2" (click)="clean()">
        <i class="fa fa-fw fa-undo"></i>{{ 'GLOBAL.CLEAN' | translate }}
      </button>
    </div>
  </div>
</div>
