<h1>{{ 'NAVIGATION.MY_DISPLAY' | translate }}</h1>

<h2>
  {{ 'NAVIGATION.MY_DISPLAY' | translate }}
  <div *ngIf="kcUser.multiDisplay" class="button-box">
    <button (click)="createNewDisplay()" class="button-primary">
      <i class="fa fa-plus"></i> {{ 'GLOBAL.NEW_DISPLAY' | translate }}
    </button>
  </div>
</h2>

<app-my-display-filters
  (filterChanged)="filtered($event)"
  (filterCleaned)="cleaned()"
></app-my-display-filters>

<div id="display-list" class="display-list" *ngIf="datasource.totalItems! > 0; else noData">
  <div ngbAccordion *ngFor="let display of datasource.currentItems" (shown)="onShowDetail($event, display)">
    <div ngbAccordionItem >
      <div ngbAccordionHeader class="display-list-item">
        <button ngbAccordionButton class="header-box">
          <span class="title">{{ display.name }}</span>
  
          <div ngbDropdown class="d-inline-block menu" (click)="$event.stopPropagation()">
            <button ngbDropdownToggle class="button-icon">
              <i class="fa fa-ellipsis-v"></i>
            </button>
            <div ngbDropdownMenu style="z-index: 99999;">
              <button
                ngbDropdownItem
                (click)="navigateToDisplay(display)"
              >
                {{ 'GLOBAL.SHOW_EDIT' | translate }}
              </button>
              <button
                ngbDropdownItem
                *ngIf="kcUser.multiDisplay"
                (click)="assignDevicesToDisplay(display)"
              >
                {{ 'MY_DISPLAY.ASSIGN_DEVICE' | translate }}
              </button>
              <button
                ngbDropdownItem
                *ngIf="!display.constructor && kcUser.multiDisplay"
                (click)="deleteDisplay(display)"
              >
                {{ 'GLOBAL.DELETE' | translate }}
              </button>
            </div>
          </div>
        </button>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <div id="display-detail" class="display-detail">
    
              <div id="display-info" class="display-detail-info">
                <div class="col-12 pt-3">
                  <label class="custom-label">
                    <span>{{ 'RECIPE.DEVICE_MODEL' | translate }}: </span>
                    <span>{{ 'DEVICE_MODEL.' + display.deviceModel | translate }}</span>
                  </label>
                </div>
                <div class="col-12 pt-3">
                  <label class="custom-label">
                    <span>{{ 'RECIPE.COOKING_MODE' | translate }}: </span>
                    <span>{{ 'COOKING_MODE.' + display.cookingMode | translate }}</span>
                  </label>
                </div>
                <div class="col-12 pt-3" (click)="toggleDevicesInfo(display)">
                  <label class="custom-label">
                    <span [ngClass]="countDevices(display) ? 'link-primary small' : '' ">
                      {{ 'MY_DISPLAY.USED_BY' | translate: { DEVICES_NUMBER: countDevices(display) } }}</span>
                  </label>
                </div>
              </div>
    
              <div id="devices-info" *ngIf="showDetailDeviceTable.get(display.id!)" class="display-detail-devices" >
                <ngx-datatable
                  class="material fixed-tooltip"
                  columnMode="force"
                  [columns]="devicesTableColumns"
                  [rows]="getDevices(display.id!)"
                >
                </ngx-datatable>  
              </div>
    
              <div id="recipe-box" *ngIf="!showDetailDeviceTable.get(display.id!)" class="display-detail-recipe d-flex align-items-center">
                <i
                  *ngIf="recipesDataSource.get(display.id!) && (recipesDataSource.get(display.id!)!.totalItems || 0) > recipesDataSource.get(display.id!)!.limit"
                  [ngClass]="{ disabled: recipesDataSource.get(display.id!) && recipesDataSource.get(display.id!)!.page === 0 }"
                  class="fa fa-chevron-left"
                  (click)="prevPage(recipesDataSource.get(display.id!)!)"
                ></i>
                <div class="d-flex flex-wrap" *ngIf="recipesDataSource.get(display.id!) && recipesDataSource.get(display.id!)!.totalItems! > 0; else noRecipes">
                  <app-recipe-box
                    *ngFor="let recipe of recipesDataSource.get(display.id!)!.currentItems"
                    [recipe]="recipe"
                    class="m-3"
                    [canDelete]="false"
                  ></app-recipe-box>
                </div>
                <i
                  *ngIf="recipesDataSource.get(display.id!) && (recipesDataSource.get(display.id!)!.totalItems || 0) > recipesDataSource.get(display.id!)!.limit"
                  [ngClass]="{
                    disabled: recipesDataSource.get(display.id!) && recipesDataSource.get(display.id!)!.page + 1 === recipesDataSource.get(display.id!)!.totalItems! / recipesDataSource.get(display.id!)!.limit
                  }"
                  class="fa fa-chevron-right"
                  (click)="prevPage(recipesDataSource.get(display.id!)!)"
                ></i>
              </div>
    
            </div>
    
          </ng-template>
        </div>
      </div>
    </div>
  </div>

</div>

<ngb-pagination
  *ngIf="(datasource.totalItems || 0) > datasource.limit"
  [pageSize]="datasource.limit"
  [page]="datasource.page + 1"
  (pageChange)="datasource.setPage($event - 1)"
  [collectionSize]="datasource.totalItems || 0"
  [maxSize]="5"
></ngb-pagination>

<ng-template #noData>
  <p>{{ 'GLOBAL.NO_DATA' | translate }}</p>
</ng-template>

<ng-template #noRecipes>
  <p>{{ 'MY_DISPLAY.NO_RECIPES' | translate }}</p>
</ng-template>

<ng-template #lastSyncTemplate let-row="row">
  <span *ngIf="row.lastSync">{{row.lastSync | fromUTCToLocale | date: 'medium':timezone:lang }}</span>
  <span *ngIf="!row.lastSync">--</span>
</ng-template>


